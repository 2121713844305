@import url("../StaticComponents/Global.css");
.header-container {
  width: 100%;
  height: 80px;
  background-color: #9cc3e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3%;
  position: fixed;
}
.headerLogo {
  width: 45px;
  height: 45px;
}
.logout {
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: #000;
  font-size: 25px;
}
.header-heading {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.location {
  font-size: 30px;
  font-weight: 600;
  color: #00AC4F;
}
.fullScreen {
  position: absolute;
  top: 35%;
  right: 15%;
  cursor: pointer;
  color: #000;
  font-size: 25px;
}
.backup {
  position: absolute;
  top: 35%;
  right: 20%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #000;
  font-size: 25px;
}
.backup:hover,
.fullScreen:hover{
  transition: all 0.3s;
  color: #1890ff;
}
.logout:hover{
  transition: all 0.3s;
  color: var(--color-lightRed);
}