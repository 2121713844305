@import url('../../Styles/StaticComponents/Global.css');
.printHeader-container{
    width: 100%;
    height: 70px;
    background-color: #eee;
    border-bottom: 2px solid #eee;
    padding: 2% 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    /* position: relative; */
}
.type{
    font-size: 20px;
    font-weight: bold;
}
.name{
    font-size: 25px;
    font-weight: bold;
}
.print-logo{
    width: 65px;
    height: 65px;
    border-radius: 50%;
}
.pd-number{
    width: 10%;
}
.pd-normal{
    width: 15%;
}
.pd-big{
    width: 30%;
}
.printHeader-icon{
    display: block;
}
@media print {
    .printHeader-icon{
        display: none;
    }
}