@import url('../../Styles/StaticComponents/Global.css');
.card-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 80%;
    /* height: 600px; */
    min-height: 70vh;
    margin: 0 auto;
    position: absolute;
    left: 10%;
    top: 5%;
}
.card-parent{
    width: 290px;
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-icon{
  width: 84px;
  height: 84px;
  background: linear-gradient(201deg, #D3FFE7 3.14%, #EFFFF6 86.04%);
  color: #00AC4F;
  font-size: 30px;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.row{
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* margin-bottom: 10px; */
    padding: 10px;
}
@media (min-width:1200px) {   
    .row{
        height: 220px;
        padding: 0;
    }
}
@media (min-width:1200px) {   
    .card-container{
        height: 450px;
    }
    .card-parent{
        width: 300px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}