.reports {
  width: 70%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 0px var(--color-black);
}
.report-container {
  width: 80%;
  /* height: 450px; */
  background-color: #ccc;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.report-row {
  width: 100%;
  height: 35px;
  background-color: #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1px;
  direction: rtl;
}
.report-col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.report-check {
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
input[type="checkbox"] {
  width: 100%;
  height: 50%;
}
.col-label {
  font-size: 16px;
  font-weight: bold;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.label-center {
  text-align: center;
}
.input {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-mashi);
  outline: none;
}
.report-btn {
  width: 120px;
  height: 100%;
  background-color: var(--color-mashi);
  color: var(--color-white);
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
}
.report-btn:hover {
  background-color: var(--color-orange);
}
