@import url("../../Styles/StaticComponents/Global.css");
.printFactor-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.printFactor-header {
  width: 95%;
  height: 120px;
  background-color: var(--color-third);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  direction: rtl;
  align-items: center;
  border: 2px solid var(--color-third);
  border-radius: 20px;
}
.header-title__container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.header-title1 {
  font-size: 50px;
  font-weight: bolder;
  color: var(--color-white);
}
.header-title2 {
  font-size: 25px;
  font-weight: bold;
  color: var(--color-white);
}
.logo {
  width: 90px;
  height: 90px;
  background-color: #eee;
  border: 2px solid var(--color-third);
  border-radius: 50%;
  margin-right: 3%;
}
.factorType-sell,
.factorType-buy {
  font-size: 25px;
  font-weight: bolder;
  color: var(--color-white);
  box-shadow: 2px 2px 10px 0px var(--color-white);
  padding: 6px 25px;
  border-radius: 20px;
}
.factorType-sell {
  background-color: var(--color-lightRed);
}
.factorType-buy {
  background-color: var(--color-first);
}
.printFactor-items {
  width: 95%;
  height: 60px;
  border: 3px solid var(--color-third);
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  direction: rtl;
}
.items-container:nth-child(1) {
  width: 50%;
}
.items-container:nth-child(2) {
  width: 30%;
}
.items-container:nth-child(3) {
  width: 20%;
}
.items-container {
  height: 100%;
  direction: rtl;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.item-name {
  width: 70px;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item-name__first {
  background-color: var(--color-third);
  color: var(--color-white);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.item-content__first {
  padding-right: 5px;
}
.item-content {
  font-size: 18px;
  font-weight: bold;
}
.printFactor-table {
  width: 95%;
  /* height: 700px; */
  border: 1px solid var(--color-third);
  border-top-right-radius: 50px;
  position: relative;
  /* background-image: url("../../../public/logoLight.png");
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position-x: 50%;
    background-position-y: 47%; */
}
.table-heading {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  direction: rtl;
}
.table-heading-head {
  font-size: 18px;
  font-weight: bold;
}
.table-heading-head:nth-child(1) {
  width: 10%;
  height: 100%;
  border-top-right-radius: 50px;
  border-bottom: 1px solid var(--color-third);
  border-top: 1px solid var(--color-black);
}
.table-heading-head:nth-child(2) {
  width: 40%;
  height: 100%;
  border-right: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-third);
  border-top: 1px solid var(--color-black);
}
.table-heading-head:nth-child(3),
.table-heading-head:nth-child(4) {
  width: 15%;
  height: 100%;
  border-right: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-third);
  border-top: 1px solid var(--color-black);
}
.table-heading-head:nth-child(5) {
  width: 20%;
  height: 100%;
  border-top: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  border-left: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-third);
}
.table-heading-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--color-third);
  color: var(--color-white);
}
.table-items {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  direction: rtl;
}
.table-item {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.table-item:nth-child(1) {
  width: 10%;
  height: 100%;
  border-top-right-radius: 50px;
  border-bottom: 1px solid var(--color-third);
}
.table-item:nth-child(2) {
  width: 40%;
  height: 100%;
  border-right: 1px solid var(--color-third);
  border-bottom: 1px solid var(--color-third);
}
.table-item:nth-child(3),
.table-item:nth-child(4) {
  width: 15%;
  height: 100%;
  border-right: 1px solid var(--color-third);
  border-bottom: 1px solid var(--color-third);
}
.table-item:nth-child(5) {
  width: 20%;
  height: 100%;
  border-right: 1px solid var(--color-third);
  border-bottom: 1px solid var(--color-third);
}
.printFactor-footer {
  width: 95%;
  max-height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  direction: rtl;
}
.owner-description {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: relative;
}
.factor-description {
  width: 99%;
  height: 40px;
  padding: 10px 5px;
  border: 1px solid var(--color-third);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}
.owner-name,
.owner-address {
  font-size: 14px;
  font-weight: bold;
  width: 80%;
}
.table-total__container {
  width: 35%;
  height: 100%;
  border: 1px solid var(--color-third);
}
.total-group {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.total-group__title {
  width: 43%;
  height: 100%;
  border-left: 1px solid var(--color-third);
  border-bottom: 1px solid var(--color-third);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.total-group__value {
  width: 57%;
  height: 100%;
  border-left: 1px solid var(--color-third);
  border-bottom: 1px solid var(--color-third);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.print-footer {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: #eee;
  /* position: fixed;
  bottom: 0; */
  border: none;
  direction: rtl;
  padding: 2%;
  border: 1px solid #eee;
  margin-top: 10px;
}
.print-footer span {
  font-size: 16px;
  font-weight: bold;
}
.barcode {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 2%;
  top: 45%;
}
