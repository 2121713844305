@import url("../../Styles/StaticComponents/Global.css");
@font-face {
  font-family: "B-NAZANIN";
  src: url("../../../public/B-NAZANIN.TTF");
}
body {
  font-family: "B-NAZANIN" !important;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 225px;
  transition: all 0.5s;
  border-radius: 28px;
background: #FFF;
box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
flex-shrink: 0;
}
.card:hover {
  width: 270px;
  height: 240px;
  overflow: hidden;
  cursor: pointer;
}
.card img {
  width: 64px;
  height: 64px;
}
.card-title{
color: #222;
font-size: 23.979px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.card-counter{
color: var(--text-primary, #222);
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

@media (min-width: 1200px) {
  .card {
    width: 260px;
    height: 220px;
    padding: 15px;
    transition: all 0.5s;
  }
  .card:hover {
    width: 280px;
    height: 250px;
  }
}
