@import url("../StaticComponents/Global.css");
.sideBar-container {
  width: 15%;
  min-height: calc(100vh - 80px);
  float: right;
  direction: rtl;
  /* background-color: var(--color-third); */
  position: absolute;
  top: 80px;
  right: 0;
  z-index: -1;
}
.dropdown-content a {
  text-decoration: none;
  color: black;
  font-size: 17px;
  width: 100%;
  padding: 5px 10px;
}
.dropdown-content a:hover {
  background-color: #bbb;
}
