@import url("../StaticComponents/Global.css");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #222; */
  background: url("../../../public/loginBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.form-container {
  width: 450px;
  margin: 0 auto;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  position: relative;
}
.login-image {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}
form {
  display: flex;
  flex-direction: column;
}
.login-input {
  padding: 12px;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #fff;
  background-color: #555;
  border: 1px solid #555;
  font-family: "Open Sans", sans-serif;
}
.login-input::placeholder {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  direction: rtl;
  float: right;
  color: #bbb;
}
.login-submit {
  padding: 10px;
  background-color: #1c4670;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
}
.login-submit:hover {
  background-color: #1c4670;
}
.login-loader {
  width: 30px;
  height: 30px;
  position: absolute;
  left: calc(50% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 3%;
}
