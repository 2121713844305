.settings-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 50px;
    gap: 10px;
    direction: rtl;
}
.settings-labbel{
    font-size: 20px;
    font-family: "B-NAZANIN";
    width: 190px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* text-align: center; */
}
.settings-input{
    width: 500px;
    height: 40px;
}
.settings-phone{
    width: 161px;
    height: 40px;
}
.settings-btn{
    width: 100px;
    height: 40px;
    position: absolute;
    top: 50px;
    left: 25%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    direction: rtl;
}
/* edit modal */
.phone-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.phone-label{
    width: 100%;
    height: 100%;
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: bold;
    font-family: "B-NAZANIN";
}
.phone-input{
    width: 150px;
}
.profileImage-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.profileImage-container form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.profileImage{
    width: 120px;
    height: 120px;
    border: 1px solid gray;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 8px;
}
.profileImage-label{
    width: 100%;
    height: 100%;
}
.profileImage-input{
    display: none;
}
 .profileImage img{
    width: 95%;
    height: 95%;
    border-radius: 8px;
 }
 .profileImage-btn{
    width: 120px;
    padding: 5px 0;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 5px;
    direction: ltr;
 }