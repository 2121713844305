.alert-container{
    width: 250px;
    height: 50px;
    background-color: #eee;
    border-right: 5px solid #ffb229;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    direction: rtl;
    padding-right: 10px;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    position: absolute;
    z-index: 2;
    left: 15%;
    top: -8%;
}