.profile-container {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  direction: rtl;
}
.profile-content {
  width: 90%;
  height: 250px;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  direction: rtl;
  gap: 40px;
  padding: 1%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  position: relative;
}
.team-logo {
  width: 60px;
  height: 60px;
  scale: 2.5;
  position: absolute;
  left: 70px;
  top: 30px;
}
.profile-content p {
  font-size: 20px;
  line-height: 1.5;
}
.profile {
  width: 500px;
  height: 250px;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  direction: rtl;
  gap: 10px;
  padding: 1%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.profile-icon {
  width: 20px;
  height: 20px;
}
.profile-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 5px solid #ddd;
}
.profile-details {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.profile-row {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  direction: rtl;
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.website-link {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
