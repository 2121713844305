*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.loading{
    width: 20px;
    height: 20px;
    background-color: transparent;
    animation: load 1s linear infinite;
}
.loader{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid var(--color-fourth);
    border-top-color: var(--color-orange);
    border-left-color: var(--color-orange);
}
@keyframes load {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}