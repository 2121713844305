* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.checkPrint-header {
  width: 100%;
  height: 60px;
  background-color: #9cc3e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  direction: rtl;
}
.checkPrint-header img {
  width: 45px;
  height: 45px;
}
.checkPrint-header span {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.checkPrint-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  direction: rtl;
}
.check-group {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  font-size: 18px;
  font-weight: bold;
}
.check-group:nth-child(even) {
  background-color: #bbb;
}
.check-group:nth-child(odd) {
  background-color: #ccc;
}
.check-group span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
}
.check-group span:first-child {
  width: 200px;
  height: 100%;
  border-left: 1px solid #9cc3e7;
  padding-right: 5%;
}
.check-group span:nth-child(2) {
  width: auto;
  height: 100%;
}
.checkPrint-signature {
  width: 100%;
  height: 120px;
  background-color: #efefef;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  direction: rtl;
  border-bottom: 3px solid #9cc3e7;
}
.checkPrint-signature span {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
.checkPrint-details {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  direction: rtl;
  padding-right: 5%;
  position: relative;
  background-color: #efefef;
}
.checkPrint-details span {
  font-size: 18px;
  font-weight: bold;
}
.checkPrint-barcod {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 5%;
  top: 15%;
}
.crop-line {
  display: block;
  margin-top: 10px;
  width: 100%;
  height: 3px;
  border-bottom: 3px dashed #000;
}
