* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "B-NAZANIN";
  src: url("../../../public/B-NAZANIN.TTF");
}
body {
  font-family: "B-NAZANIN" !important;
}
:root {
  --color-first: #1dc690;
  --color-second: #278ab0;
  --color-third: #1c4670;
  --color-fourth: #eaeae0;
  --color-white: #fff;
  --color-black: #000;
  --color-orange: #ffb229;
  --color-mashi: #1d7874;
  --color-lightRed: #ff6347;
  --color-fifth: #0d0c22;
  /* #F0F7FF */
  --color-content: #F0F0F0
}
.content {
  width: 85%;
  min-height: 100vh;
  background-color: var(--color-content);
  position: fixed;
  top: 80px;
  padding: 2% 0;
}
.btn {
  font-family: "B-NAZANIN";
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btn-print {
  background-color: var(--color-lightRed);
}
.btn-save {
  background-color: var(--color-first);
}
.action-space{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
/* file component */
.file-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.file-header {
  width: 90%;
  height: 60px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
  margin: 0 auto;
  direction: rtl;
  border-radius: 5px;
  margin-bottom: 20px;
}
.file-header:nth-child(2){
  margin-bottom: 10px;
  margin-top: -5px;
}
.file-content {
  width: 90%;
  max-height: 400px !important;
  margin: 0 auto;
  /* overflow-y: scroll; */
}
/* search component */
.search {
  border: none;
  outline: none;
  width: 220px;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  direction: rtl;
  border: 2px solid #eee;
}
.search::placeholder {
  font-size: 15px;
}
::placeholder{
  font-family: "B-NAZANIN";
}
/* custome input */
.inputRep{
  width: 225px;
}
.inputDateRep{
  width: 225px;
  height: 40px;
  border: none;
  outline: none;
  border: 1px solid rgba(5, 5, 5, 0.06);
  background-color:  #ffffff;
  padding: 7px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
}
.inputDateRoz{
  width: 180px;
  height: 40px;
  border: none;
  outline: none;
  border: 1px solid rgba(5, 5, 5, 0.06);
  background-color:  #ffffff;
  padding: 7px;
  border-radius: 8px;
  font-size: 16px;
}
.inputDateFactor{
  width: 200px;
  height: 40px;
  border: none;
  outline: none;
  border: 1px solid rgba(5, 5, 5, 0.06);
  background-color:  #ffffff;
  padding: 7px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.inputDate{
  width: 471px;
  height: 40px;
  border: none;
  outline: none;
  border: 1px solid rgba(5, 5, 5, 0.06);
  background-color:  #ffffff;
  padding: 7px;
  border-radius: 8px;
  font-size: 16px;
}
.inputDate:hover,
.inputDateFactor:hover{
  border-color: #4096ff;
}
.inputDate:focus,
.inputDateFactor:focus{
  border-color: #1677ff;;
  -webkit-box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2); 
    box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2);
}
/* adding popup */
.add-content {
  width: 40%;
  height: auto;
  margin: 0 auto;
  background-color: var(--color-fourth);
  box-shadow: 2px 2px 10px 0px var(--color-black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  position: absolute;
  left: 30%;
}
.add-header,
.add-footer {
  width: 100%;
  height: 50px;
  background-color: var(--color-mashi);
}
.add-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
}
.search-header {
  width: 100%;
  height: 50px;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
  padding: 0 2%;
  border-bottom: 2px solid #aaa;
}
.typeCount-container {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.typeCount-container span {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-third);
}
.typeList-header {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
  background-color: var(--color-first);
}
.typeList-header span:first-child,
.typeList-group span:first-child {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10%;
}
.typeList-header span:nth-child(2),
.typeList-group span:nth-child(2) {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.typeList-body {
  width: 100%;
  height: auto;
  overflow-y: scroll;
}
.typeList-group {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
}
.typeList-group:nth-child(odd) {
  background-color: #eee;
}
.add-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.add-header span {
  font-size: 18px;
  font-weight: 500;
}
/* this is temporary logo design */
.add-header img {
  width: 16px;
  height: 16px;
}
.close {
  cursor: pointer;
}
/* add s body */
.addDrug-body {
  width: 100%;
  height: 280px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.addRemittamce-body {
  width: 100%;
  height: 350px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.addType-body {
  width: 100%;
  height: 150px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.add-input {
  border: none;
  outline: none;
  width: 70%;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  direction: rtl;
  border: 2px solid #ccc;
  /* background-color: #ddd; */
}
.add-input__disable {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--color-black);
}
.add-input::placeholder {
  font-size: 15px;
}
.action {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.add-popup {
  position: absolute;
  top: 170px;
  left: 32.5%;
  width: 400px;
  /* height: 420px; */
  padding-bottom: 10px;
  background-color: #fff;
  color: #fff;
  border-radius: 4px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  z-index: 3;
  direction: rtl;
}
.addPopup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-third);
  width: 100%;
  height: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 2%;
  margin-bottom: 5px;
}
.addPopup-header span {
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}
.close {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.addPopup-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.text-input {
  width: 90%;
  height: 30px;
  padding: 20px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid #eee;
  font-size: 15px;
  color: #808080;
}
.text-input::placeholder {
  font-size: 14px;
}
.popup-btn {
  width: 150px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 15px;
  padding: 12px;
  cursor: pointer;
  background-color: #1dc690;
  color: #fff;
  transition: all 0.5s;
}
.add-btn:hover {
  opacity: 0.8;
}
.select-btn {
  width: 100px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid #eee;
  font-size: 15px;
  color: #808080;
  cursor: pointer;
  text-align: center;
}
.select-btn2 {
  width: 90%;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid #eee;
  font-size: 15px;
  color: #808080;
  cursor: pointer;
  padding-right: 20px;
}
.form-group {
  width: 90%;
  max-height: 40px;
  margin: 0 auto;
}
.form-group .text-input {
  width: 47.5%;
  max-height: 40px;
}
.date-option2 {
  direction: ltr;
}
.date-option2 input {
  width: 55px;
  background-color: #ccc;
  border: none;
  outline: none;
  height: 25px;
  text-align: center;
}
.date-option {
  width: 90% !important;
  margin-top: 2px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 20px;
}
.form-group__label {
  font-size: 18px;
  color: #808080;
  padding: 0 2px;
  text-align: center;
}
.active {
  padding: 5px 10px;
  text-align: center;
  background-color: #1dc690;
  border-radius: 20px;
  color: #fff;
}
.disActive {
  padding: 5px 10px;
  text-align: center;
  background-color: #ffb229;
  border-radius: 20px;
  color: #fff;
}
.group2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: right;
}
.group2 span {
  padding: 5px;
}
.group2 span:first-child {
  font-size: 14px;
  font-weight: 600;
}
.group2 span:nth-child(2) {
  border: 1px solid var(--color-third);
  background-color: var(--color-third);
  width: 200px;
  margin-left: auto;
  text-align: center;
}
.group2 input[type="date"] {
  background-color: #fff;
  color: var(--color-third);
}
.group2 select {
  width: 100px;
  min-width: 120px;
  min-height: 40px;
  margin-left: auto;
  padding: 5px;
  border: none;
  outline: none;
  background-color: var(--color-third);
  border: 1px solid var(--color-third);
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
}
.group3 {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 2px;
  text-align: right;
  background-color: #eee;
  border: 1px solid var(--color-third);
  border-radius: 4px;
}
.group3 span:first-child {
  font-size: 14px;
  font-weight: 600;
  padding-left: 5px;
}
.group3 span:nth-child(2) {
  text-align: center;
}
.group-date {
  width: 90%;
  direction: ltr;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.group-date input {
  width: 100%;
  height: 30px;
  padding: 20px;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid #eee;
  font-size: 15px;
  color: #808080;
}
/* add-popup__searcher */
.addPopup__searcher,
.addPopup__searcher2,
.addPopup__searcher3 {
  background-color: #fff;
  z-index: 3;
  position: absolute;
  top: 45px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.addPopup__searcher {
  width: 100%;
  height: 300px;
}
.addPopup__searcher2 {
  width: 450px;
  height: 300px;
  position: absolute;
  top: 80px;
  right: 0;
}
.addPopup__searcher3 {
  width: 450px;
  height: 300px;
  position: absolute;
  top: 0;
  right: 0;
}
.addPopup__searcher-header,
.addPopup__searcher-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-third);
  color: #fff;
  width: 100%;
  height: 40px;
  padding: 2%;
  margin-bottom: 5px;
}
.addPopup__searcher-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.addPopup__searcher-footer {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.addPopup__searcher-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  padding: 0 1%;
  background-color: #fff;
}
.table-container2 {
  width: 100%;
  height: 220px;
  overflow-y: scroll;
}
.table-heading__popup {
  padding-right: 5px;
}
/* print design */
.printFooter {
  width: 100%;
  height: 25px;
  background-color: var(--color-third);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
/* addPopup__typeSearcher */
.addPopup__typeSearcher {
  width: 30%;
  height: 300px;
  background-color: #fff;
  z-index: 3;
  position: absolute;
  top: 109px;
  left: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  direction: rtl;
}
/* scroll design */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* dark */
.dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 4px;
  z-index: 1;
  cursor: no-drop;
}
/* Table Styles */
.print-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
}
table{
  direction: rtl;
  font-size: 16px;
  font-weight: 600;
}
.table {
  direction: rtl;
  width: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
}
.tableHead-row{
  width: 100%;
  height: 35px;
  font-size: 18px;
  color: #000;
  background-color: #999;
  line-height: 1.4;
  position: sticky;
  top: 0;
}
.table-row{
  width: 100%;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  line-height: 1.4;
  top: 0;
  border-top: 1px solid #000;
}
tbody td {
  text-align: center;
}
.printFooter {
  width: 100%;
  height: 35px;
  background-color: #999;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.minesAmount{
  direction: ltr;
  color: var(--color-lightRed);
}